<template>
	<div style="background: white; padding: 10px 20px;">
		<el-form :inline="true" :model="formData" :rules="rules" ref="form">
			<el-form-item label="新密码" prop="password">
				<el-input
					placeholder="请输入新密码"
					v-model="formData.password"
					show-password
				></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit">提交</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { Form, FormItem, Input, Button, Message } from "element-ui";
export default {
	name: "ManagerModifyPassword",
	components: {
		ElForm: Form,
		ElFormItem: FormItem,
		ElInput: Input,
		ElButton: Button,
	},
	data() {
		return {
			formData: {
				password: "",
			},
			rules: {
				password: [
					{ required: true, message: "请输入密码", trigger: "blur" },
					{
						min: 8,
						max: 40,
						message: "长度在 8 到 40 个字符",
						trigger: "blur",
					},
				],
			},
		};
	},
	methods: {
		async onSubmit() {
			this.$refs.form.validate(async (valid) => {
				if (!valid) {
					Message.error("请输入完整正确后再提交哦！");
					return false;
				}

				try {
					const {data} = await this.$api.manager.manager.modifyPassword(
						this.formData.password
					);
					if (data.code === "OK") {
						Message.success("修改密码成功！");
						this.formData.password = "";
					} else {
						Message.error("错误：" + data.errmsg);
					}
				} catch (e) {
					Message.error("错误：" + e);
				}
			});
		},
	},
};
</script>

<style>
</style>